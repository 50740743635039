import {isObject, isString, isUndefined, isValidString} from "@kubric/utils";
import {useLocation} from "@reach/router";
import {Link, graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import ArrowRight from "react-feather/dist/icons/arrow-right";
import X from "react-feather/dist/icons/x";
import {Helmet} from "react-helmet";
import {Link as SpyLink} from "react-scroll";

import Layout from "../../../components/Layout";
import ShareTray from "../../../components/ShareTray/ShareTray";
import StructuredData, {
  StructuredDataType,
} from "../../../components/StructuredData";
import EmailCTA from "../../../components/commons/EmailCTA";
import {MasonIcon} from "../../../components/icons/Mason";
import {MMLogo} from "../../../components/icons/PostDetailsPageMMLogo";
import BlogCard from "../../../components/pages/feed/CardsSection/cards/BlogCard";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {useMediaQuery} from "../../../hooks/useMediaQuery";
import {commonJoinList} from "../../../lib/actions";
import checkWithinLastNDays from "../../../utils/checkWithinLastNDays";
import getReadingTime from "../../../utils/readingMinutes";
import {useScrollPercentage} from "../../../utils/scrollPercentage";
import {useWindowDimensions} from "../../../utils/windowDimensions";
import "./PostDetail.scss";
import {GhostCTA, Subscribe, Takeaways, Tip} from "./components";

const idCounter = {};

const uniqueId = (prefix = "$uniquest$") => {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }
  idCounter[prefix] += 1;
  const id = idCounter[prefix];
  return `${prefix}${id}`;
};

const getStringFromParsedHtml = (html) => {
  // get element to be examined for string
  const child = html?.props?.children;

  // if child undefined, return empty string
  if (isUndefined(child)) {
    return "";
  }

  // if child already a string, return it
  if (isString(child)) {
    return child.trim();
  }

  // if child is array of html parsed objects, return recursively and join all results with ' '
  if (Array.isArray(child)) {
    return child
      .map((e) => (isString(e) ? e : getStringFromParsedHtml(e)))
      .join(" ")
      .trim();
  }

  // if child already an html parsed object, return recursively
  if (isObject(child)) {
    return getStringFromParsedHtml(child).trim();
  }

  // else return empty
  return "";
};

const blogContentHandler = (content) => {
  return content.map((el) => {
    if (el.type === "takeaways") {
      return <Takeaways key={uniqueId("takeaway")} {...el.props} />;
    }
    if (el.type === "cta") {
      return <GhostCTA key={uniqueId("ghost-cta")} {...el.props} />;
    }
    if (el.type === "tip") {
      return <Tip key={uniqueId("tip")} {...el.props} />;
    }
    if (
      el.type === "p" &&
      !el?.props?.children?.length &&
      el?.props?.children?.type === "subscribe"
    ) {
      return <Subscribe key={uniqueId("subscribe")} {...el.props} />;
    }
    if (el.type === "p" && isUndefined(el?.props?.children)) {
      return <p>&nbsp;</p>;
    }
    return el;
  });
};

const PostDetail = ({pageContext, data: {blogs, blogSubscription} = {}}) => {
  const {
    data: {
      title,
      feature_image: featureImage,
      og_description: description,
      meta_title: metaTitle,
      meta_description: metaDescriptions,
      canonical_url: canonicalUrl,
      localImage,
      tags,
      authors: [
        {
          profile_image: profileImage,
          name: authorName,
          url: authorUrl,
          bio: authorBio,
          location: authorDesignation,
          website: authorLinkedin,
          twitter: authorTwitter,
        },
      ],
      html,
      published_at: publishedAt,
      updated_at: updatedAt,
    } = {},
  } = pageContext;

  const blogSubscriptionData =
    blogSubscription?.nodes[0]?.blogSubscriptionSection;

  const isMobile = useMediaQuery("(max-width: 900px)");

  const {href: pageUrl} = useLocation();

  const publishDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(publishedAt));

  // to show the subcribe card on right side
  const [showSubscribeCard, setShowSubscribeCard] = React.useState(false);

  // showing new subscriber card only for posts with following array of tags
  const showNewSubscribeCard = React.useMemo(
    () =>
      ["shopify", "conversions", "bfcm", "sales"].some((slug) =>
        tags
          .filter(({name}) => !name.startsWith("#"))
          .map(({slug}) => slug)
          .includes(slug)
      ),
    [JSON.stringify(tags)]
  );
  // to check if the subscribe card was closed by the user
  const [isSubscribeCardClosed, setIsSubscribeCardClosed] =
    React.useState(false);
  // storing subscriber email in local storage
  const [subscriberEmail, setSubscriberEmail] = useLocalStorage(
    "subscriberEmail",
    ""
  );
  // to show the message after subcribe action completed
  const [subscribed, setSubscribed] = React.useState(false);

  const {height} = useWindowDimensions();
  const {scrollPercentage} = useScrollPercentage();

  // once user scrolls 50% height, show the subscribe card
  React.useEffect(() => {
    if (scrollPercentage > 50) {
      setShowSubscribeCard(true);
    }
  }, [scrollPercentage]);

  // forming sections for scroll-spy to work properly
  const blogHtml = React.useMemo(
    () =>
      parse(html).reduce(
        (prev, curr) => {
          if (curr.type === "h2") {
            return [
              ...prev,
              {
                type: "navSection",
                id:
                  curr.props?.id && isValidString(curr.props.id)
                    ? curr.props?.id
                    : uniqueId("section"),
                navTitle: getStringFromParsedHtml(curr),
                content: [{...curr, props: {...curr.props, id: undefined}}],
              },
            ];
          }
          let lastEl = prev.pop();
          return [...prev, {...lastEl, content: [...lastEl.content, curr]}];
        },
        [{type: "content", content: []}]
      ),
    [html]
  );

  // list of headings that are to be shown in navigation and that have a valid id
  const navigationListItems = React.useMemo(
    () =>
      blogHtml
        .filter((e) => e.type === "navSection")
        .map((e) => ({id: e.id, text: e.navTitle})) || [],
    [blogHtml]
  );

  // Elements to be shown in more articles section
  const moreArticlesElements = React.useMemo(() => {
    const cards = [
      ...blogs.nodes.map((blog) => ({
        data: {
          ...blog,
          shouldShowNewTag: checkWithinLastNDays(blog.published_at, 5),
          readingTime: getReadingTime(blog.html),
        },
      })),
    ];

    // get the cards which includes current blog's tags and pick 3 from them
    return cards
      .filter((card) => {
        return (
          publishedAt !== card.data.published_at &&
          tags
            .filter(({name}) => !name.startsWith("#"))
            .map(({slug}) => slug)
            .every((tag) => card.data.tags.map(({slug}) => slug).includes(tag))
        );
      })
      .slice(0, 3)
      .map(
        ({data}) => (
          <BlogCard
            data={{
              isNew: data?.shouldShowNewTag,
              ...data,
            }}
            feedMode
            color='#02152d'
            bgColor='white'
            theme={{feed: "more-articles-card"}}
          />
        ),
        []
      );
  });

  // to show header box shadow
  const [showHeaderShadow, setShowHeaderShadow] = React.useState(false);
  React.useEffect(() => {
    setShowHeaderShadow(scrollPercentage > 0);
  }, [scrollPercentage]);

  return (
    <Layout headerProps={{showHeaderShadow}}>
      <Helmet title={metaTitle ?? title} htmlAttributes={{lang: "en"}}>
        <meta name='description' content={metaDescriptions ?? description} />
        <meta property='og:type' content='article' />
        <meta property='og:url' content={useLocation()} />
        <meta property='og:title' content={metaTitle ?? title} />
        <meta property='og:image' content={featureImage} />
        <meta
          property='og:description'
          content={metaDescriptions ?? description}
        />
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>
      <StructuredData
        type={StructuredDataType.BLOG}
        data={{
          title,
          image: featureImage,
          datePublished: publishedAt,
          dateModified: updatedAt,
          author: {name: authorName, url: authorUrl, image: profileImage},
        }}
      />
      <div className='post-deets-wrap mt-24'>
        {/* Header / Hero section */}
        <div className='post-header mb-12 flex flex-col items-center'>
          {isMobile && <h1 className='post-title text-left light'>{title}</h1>}
          <div className='p-image flex items-center justify-center'>
            <GatsbyImage image={localImage.childImageSharp.gatsbyImageData} />
          </div>
          <div className='p-content flex justify-between items-center'>
            <div className='p-description f-author flex'>
              <img
                src={profileImage}
                className='w-14 h-14 rounded-full'
                alt={authorName}
              />
              <div className='p-info'>
                <span className='author-name'>{authorName}</span>
                <div className='p-content-info flex'>
                  <span>{publishDate}</span>
                  <span>{getReadingTime(html)} min read</span>
                </div>
              </div>
            </div>
            <div className='p-tags flex items-center'>
              {tags
                .filter(({name}) => !name.startsWith("#"))
                .map((tag) => (
                  <Link to={`/blog/tag/${tag.slug}`} className='post-tag'>
                    {tag.name}
                  </Link>
                ))}
            </div>
          </div>
          {!isMobile && <h1 className='post-title text-left light'>{title}</h1>}
        </div>

        {/* Content */}
        <main>
          {/* Left navigation sectoin */}
          {!!navigationListItems.length && !isMobile && (
            <div className='post-navigation flex items-start justify-start flex-col'>
              <span className='nav-heading'>IN THIS ARTICLE</span>
              <div className='nav-list flex flex-col'>
                {navigationListItems.map(({id, text}) => (
                  <SpyLink
                    className='nav-list-item'
                    key={id}
                    activeClass='active'
                    to={id}
                    spy
                    smooth
                    offset={-(height / 2)}
                    duration={500}
                  >
                    <ArrowRight className='nav-arrow' />
                    {text}
                  </SpyLink>
                ))}
              </div>
              <div className='nav-share'>
                <span>FOLLOW US ON</span>
                <ShareTray className='share-tray' />
                <Link to='/'>About Mason</Link>
              </div>
            </div>
          )}
          {/* Main post content section */}
          <div className='post-content'>
            {blogHtml.map((el) => {
              if (el.type === "navSection") {
                return (
                  <section id={el.id}>{blogContentHandler(el.content)}</section>
                );
              }
              return blogContentHandler(el.content);
            })}
            <section id='post-author-info' className='post-author-info'>
              <div className='author-profile-break'>
                <p className='author-profile-break-title'>
                  This article was written by
                </p>
                <hr className='author-profile-break-line' />
              </div>
              <div className='author-profile'>
                <div className='author-image'>
                  <img
                    src={profileImage}
                    className='rounded-full'
                    alt={authorName}
                  />
                </div>
                <div className='author-description'>
                  <span className='name'>{authorName}</span>
                  <div className='designation'>
                    <span>
                      {authorDesignation}
                      {!isMobile && " | "}
                    </span>
                    <ShareTray
                      className='author-share'
                      show={["linkedin", "twitter"]}
                      customUrl={{
                        linkedin: authorLinkedin,
                        twitter: `https://twitter.com/${authorTwitter}`,
                      }}
                    />
                  </div>
                  {!isMobile && <div className='author-bio'>{authorBio}</div>}
                </div>
              </div>
              {isMobile && <div className='author-bio'>{authorBio}</div>}
            </section>
          </div>
          {/* Right subscription card + share article section */}
          {!isMobile && (
            <div className='post-subscription flex items-center justify-end flex-col'>
              {!isSubscribeCardClosed && (
                <div
                  className={`subscription-card flex flex-col${
                    showSubscribeCard ? " show" : ""
                  }${subscribed ? " subscribed" : ""}${
                    showNewSubscribeCard ? " newCard" : ""
                  }`}
                >
                  <X
                    onClick={() => setIsSubscribeCardClosed(true)}
                    className='close-subscription'
                  />
                  <div className='icon-heading-container flex flex-col'>
                    {showNewSubscribeCard ? <MMLogo /> : <MasonIcon />}
                    <div className='subscribe-heading'>
                      {showNewSubscribeCard
                        ? blogSubscriptionData?.desktopViewNew?.heading
                        : subscribed
                        ? blogSubscriptionData?.desktopViewOld?.successHeading
                        : blogSubscriptionData?.desktopViewOld?.heading}
                    </div>
                  </div>
                  {showNewSubscribeCard && (
                    <GatsbyImage
                      className='subscription-image'
                      image={
                        blogSubscriptionData?.desktopViewNew?.image?.image
                          ?.asset?.gatsbyImageData
                      }
                      alt={blogSubscriptionData?.desktopViewNew?.image?.alt}
                    />
                  )}
                  <span className='flex'>
                    {showNewSubscribeCard
                      ? blogSubscriptionData?.desktopViewNew?.subHeading
                      : subscribed
                      ? blogSubscriptionData?.desktopViewOld?.successSubHeading
                      : blogSubscriptionData?.desktopViewOld?.subHeading}
                  </span>
                  {showNewSubscribeCard ? (
                    <>
                      <div className='subscription-items flex flex-col'>
                        {blogSubscriptionData?.desktopViewNew?.items.map(
                          (item) => {
                            return (
                              <div className='subscription-item flex'>
                                <GatsbyImage
                                  className='subscription-item-icon'
                                  image={
                                    item?.icon?.image?.asset?.gatsbyImageData
                                  }
                                  alt={item?.icon?.image?.alt}
                                />
                                <span>{item?.text}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <OutboundLink
                        className='subscription-cta'
                        href={blogSubscriptionData?.desktopViewNew?.ctaUrl}
                        id='add-mm-app'
                      >
                        {blogSubscriptionData?.desktopViewNew?.ctaText}
                      </OutboundLink>
                    </>
                  ) : (
                    !subscribed && (
                      <EmailCTA
                        id='subscribe-to-blog'
                        data={{
                          action: "masonHomeCtaFn",
                          cta: blogSubscriptionData?.desktopViewOld?.ctaText,
                          placeholder:
                            blogSubscriptionData?.desktopViewOld
                              ?.emailPlaceholder,
                          email: subscriberEmail,
                        }}
                        theme={{
                          container: "ctaContainer",
                        }}
                        propOnClick={({email}) => {
                          return commonJoinList({
                            tags: ["Blog Subscriber"],
                            email,
                            list: "others",
                            account: "mailchimp",
                          }).finally(() => {
                            setSubscribed(true);
                            setSubscriberEmail(email);
                          });
                        }}
                      />
                    )
                  )}
                </div>
              )}
              <div className='share-tray-container flex flex-col'>
                <span>SHARE THIS ARTICLE</span>
                <ShareTray
                  className='share-tray'
                  show={["facebook", "linkedin", "twitter"]}
                  shareArticle={["facebook", "linkedin", "twitter"]}
                  shareData={{url: pageUrl, title}}
                />
              </div>
            </div>
          )}
        </main>
        {moreArticlesElements.length > 0 && (
          <div className='post-more-articles'>
            <div className='more-articles-heading'>
              <hr className='heading-bg-line' />
              <span>More Articles</span>
            </div>
            <div className='more-articles-container'>
              {moreArticlesElements}
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <div className='post-subscription flex items-center justify-end flex-col'>
              <div
                className={`subscription-card flex flex-col${
                  showSubscribeCard ? " show" : ""
                }${subscribed ? " subscribed" : ""}`}
              >
                <div className='icon-heading-container flex flex-col'>
                  <div className='subscribe-heading'>
                    {subscribed
                      ? blogSubscriptionData?.mobileView?.successHeading
                      : blogSubscriptionData?.mobileView?.heading}
                  </div>
                </div>
                <EmailCTA
                  showResponse
                  data={{
                    action: "masonHomeCtaFn",
                    cta: blogSubscriptionData?.mobileView?.ctaText,
                    placeholder:
                      blogSubscriptionData?.mobileView.emailPlaceholder,
                    email: subscriberEmail,
                    response:
                      blogSubscriptionData?.mobileView?.successSubHeading,
                  }}
                  theme={{
                    container: "ctaContainer",
                    responseContainer: "ctaContainer",
                  }}
                  propOnClick={({email}) => {
                    return commonJoinList({
                      tags: ["Blog Subscriber"],
                      email,
                      list: "others",
                      account: "mailchimp",
                    }).finally(() => {
                      setSubscribed(true);
                      setSubscriberEmail(email);
                    });
                  }}
                />
              </div>
            </div>
            <div className='share-tray-container flex flex-col'>
              <span>SHARE THIS ARTICLE</span>
              <ShareTray
                className='share-tray'
                show={["facebook", "linkedin", "twitter"]}
                shareArticle={["facebook", "linkedin", "twitter"]}
                shareData={{url: pageUrl, title}}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    blogs: allGhostPost {
      nodes {
        html
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        og_image
        og_description
        tags {
          slug
          name
        }
        title
        authors {
          name
          profile_image
          url
          location
        }
        slug
        published_at
        updated_at
      }
    }
    blogSubscription: allSanityBlogDetailsPage {
      nodes {
        blogSubscriptionSection {
          desktopViewOld {
            heading
            subHeading
            successHeading
            successSubHeading
            emailPlaceholder
            ctaText
          }
          desktopViewNew {
            heading
            image {
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
              alt
            }
            subHeading
            items {
              _key
              icon {
                image {
                  asset {
                    gatsbyImageData
                    url
                  }
                }
                alt
              }
              text
            }
            ctaText
            ctaUrl
          }
          mobileView {
            ctaText
            emailPlaceholder
            heading
            successHeading
            successSubHeading
          }
        }
      }
    }
  }
`;

export default PostDetail;
